import React from 'react';
import { useTranslation } from 'react-i18next';
import customStyles from './ApproveAccordancesModal.module.scss';
import CustomCheckbox from '../../../../Util/CustomCheckbox/CustomCheckbox';
import { setEverySelectedAndVisible } from './ApproveAccordancesModal';

type Props = {
  list: any[];
  setList: Function;
  updateListItem: Function;
};

const SingleProjectList = ({ list, setList, updateListItem }: Props) => {
  const { t } = useTranslation();
  const { checkBoxListContainer, possibleActionText } = customStyles;

  return (
    <>
      <CustomCheckbox
        labelText={t('select_all')}
        isChecked={list.every(i => i.selected)}
        handleOnClick={() =>
          setList(
            setEverySelectedAndVisible(
              list,
              list.some(o => !o.selected),
            ),
          )
        }
        customStyle={{ paddingTop: 0 }}
      />
      <ul className={checkBoxListContainer}>
        {list.map(item => (
          <li key={item.id}>
            <CustomCheckbox
              labelText={item.criticalIssue.name}
              isChecked={item.selected}
              handleOnClick={() => updateListItem(item)}
              customStyle={{ paddingTop: 0, fontFamily: 'Rubik', fontSize: 12, fontWeight: 500 }}
            />
            <p className={possibleActionText} onClick={() => updateListItem(item)}>
              {item?.possibleAction?.name}
            </p>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SingleProjectList;
