import React, { useState } from 'react';
import CreateDraftNewAgreementsTable from './CreateDraftNewAgreementsTable';
import { useTranslation } from 'react-i18next';
import AccordanceButton from '../../../../Util/Buttons/AccordanceButton/AccordanceButton';
import Project from '../../../../../models/Project';
import { useSelector } from 'react-redux';
import SpinningLoader from '../../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { AgreementStatusStrings } from '../../../../../enums/accordanceStateEnum';
import { useGetAgreements } from '../../../../../hooks/useGetAgreements';
import { useAgreementsHelpContext } from '../../../../../context/AgreementsContext';

interface Prop {
  projectId: number;
}

export default function CreateDraftNewAgreements({ projectId }: Prop) {
  const { t } = useTranslation();
  const [showNewAccordanceModal, setShowNewAccordanceModal] = useState(false);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const getCurrentReview = useSelector<any, any>(state => state.currentReview);

  const { id: idReview } = getCurrentReview;

  return (
    <div className="content-tabs">
      <h3>3. {t('new_create_new_draft_agreements')}</h3>
      <div className="text-box">
        <p>{t('new_create_new_draft_agreements_text')}</p>
      </div>
      <div className="draft">
        <AccordanceButton
          customLabel={t('new_draft_accordances')}
          disabled={false}
          handleClick={() => setShowNewAccordanceModal(true)}
        />
      </div>
      {false ? (
        <div className="content-loader">
          <SpinningLoader />
        </div>
      ) : (
        <div className="table-check-current table-draft">
          <CreateDraftNewAgreementsTable
            projectId={projectId}
            openModal={showNewAccordanceModal}
            setCloseModal={setShowNewAccordanceModal}
            currentProject={currentProject}
          />
        </div>
      )}
    </div>
  );
}
