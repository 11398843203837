/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useMemo } from 'react';

type DataItem = { [key: string]: any };

const useDataNormalize = (attributes: string[], filterKeys: string[], inputData: DataItem[]) => {
  /** Creates a base object with all keys defined in attributes and initializes their values to null.
   *
   * @param attributes An array of strings that define the keys of the base object.
   * @returns A base object with keys initialized to null.
   */
  const createBaseObject = attributes => {
    const baseObj = {};
    attributes.forEach(attr => {
      baseObj[attr] = null;
    });
    return baseObj;
  };

  /** Verifies that each key filter is present in at least one input object.
   *
   * @param inputData The input objects.
   * @param filterKeys The keys to filter.
   */
  const validateFilterKeys = (inputData, filterKeys) => {
    const firstItem = inputData.length > 0 ? inputData[0] : null;
    if (!firstItem || typeof firstItem !== 'object' || firstItem === null) {
      throw new Error('Input data is empty or its first item is not an object.');
    }

    const availableKeys = Object.keys(firstItem);
    return filterKeys.filter(key => availableKeys.includes(key));
  };

  const parseNumberIfApplicable = value => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      return parseFloat(value);
    }
    return value;
  };

  //Validation for return correct format date

  const formatDate = (dateString: string): string => {
    const dateFormat = moment(dateString).format('L');

    return dateFormat;
  };

  const isValidDate = value => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}(?: \d{2}:\d{2}:\d{2}(?:\.\d{1,7})?)?$/;

    if (dateRegex.test(value)) {
      return formatDate(value);
    } else {
      return value;
    }
  };

  /** Extracts values from a given object based on a set of keys.
   *
   * @param item The object from which values will be extracted.
   * @param keys An array of keys to determine which values to extract from the object.
   * @returns An array of values extracted from the object.
   */

  const extractValues = (item, keys) => {
    console.log('item : ', item);
    console.log('keys : ', keys);
    //return keys.map(key => parseNumberIfApplicable(item[key]));

    //Functionality to change the date format is added.
    return keys.map(key => isValidDate(parseNumberIfApplicable(item[key])));
  };

  /**  Transforms an array of input objects into an array of objects with a defined structure.
   *
   * The structure is defined in attributes, and the values are extracted based on filterKeys.
   * @param inputData An array of objects to be transformed.
   * @param baseObject An object that defines the base structure for the transformation.
   * @param filterKeys Keys used to extract values from the input objects.
   * @returns An array of transformed objects.
   */
  const transformData = (inputData, baseObject, filterKeys) => {
    const validKeys = validateFilterKeys(inputData, filterKeys);
    return inputData.map(item => {
      const values = extractValues(item, validKeys);

      const transformedItem = { ...baseObject };
      attributes.forEach((attr, index) => {
        transformedItem[attr] = values[index];
      });
      return transformedItem;
    });
  };

  /** useMemo is used to memorize the result of transformData, avoiding unnecessary calculations.
   *
   * It is only recalculated when attributes, filterKeys, or inputData change.
   */
  const transformedData = useMemo(() => {
    if (!inputData || inputData.length === 0 || !inputData[0] || typeof inputData[0] !== 'object') {
      return [];
    }
    const baseObject = createBaseObject(attributes);
    return transformData(inputData, baseObject, filterKeys);
  }, [attributes, filterKeys, inputData]);

  return transformedData;
};

export default useDataNormalize;

/*
type DataItem = { [key: string]: any };

const useDataNormalize = (
    attributes: string[],
    filterKeys: string[],
    inputData: DataItem[]
) => {


    const createBaseObject = (attributes) => {
        const baseObj = {};
        attributes.forEach(attr => {
            baseObj[attr] = null;
        });
        return baseObj;
    };


    const validateFilterKeys = (inputData, filterKeys) => {
        // Check if inputData is non-empty and its first element is an object
        const firstItem = inputData.length > 0 ? inputData[0] : null;
        if (!firstItem || typeof firstItem !== 'object' || firstItem === null) {
            throw new Error('Input data is empty or its first item is not an object.');
        }

        const availableKeys = Object.keys(firstItem);
        filterKeys.forEach(key => {
            if (!availableKeys.includes(key)) {
                throw new Error(`The filter key '${key}' does not match any key in the input objects. Available keys: [${availableKeys.join(', ')}]`);
            }
        });
    };

    const parseNumberIfApplicable = (value) => {
        if (!isNaN(value) && !isNaN(parseFloat(value))) {
            return parseFloat(value);
        }
        return value;
    };


    const extractValues = (item, keys) => {
        return keys.map(key => parseNumberIfApplicable(item[key]));
    };

    const transformData = (inputData, baseObject, filterKeys) => {
        validateFilterKeys(inputData, filterKeys);
        return inputData.map(item => {
            const values = extractValues(item, filterKeys);
            const transformedItem = { ...baseObject };
            attributes.forEach((attr, index) => {
                transformedItem[attr] = values[index];
            });
            return transformedItem;
        });
    };

    const transformedData = useMemo(() => {
        if (!inputData || inputData.length === 0 || !inputData[0] || typeof inputData[0] !== 'object') {
            return [];
        }
        const baseObject = createBaseObject(attributes);
        return transformData(inputData, baseObject, filterKeys);
    }, [attributes, filterKeys, inputData]);

    return transformedData;
};

export default useDataNormalize;

*/
