/*--- state types ---*/
export enum StatusLabelTab {
  complete = 0,
  incomplete = 1,
  null = 2,
}

export enum revisionNumberStrings {
  'Primera' = 1,
  'Segunda' = 2,
  'Tercera' = 3,
  'Cuarta' = 4,
  'Quinta' = 5,
  'Sexta' = 6,
  'Septima' = 7,
  'Octava' = 8,
  'Novena' = 9,
  'Decima' = 10,
}

/*--- Forms tabs ---*/
export interface GeneralData {
  type: 'generalData';
  institution_borrower: string;
  objectives: string;
  inputOpt: string;
  criteria: string;
  institutions_review: string;
  aditional_info_general_data: string;
  status: boolean;
}

export interface AspectsGenerals {
  type: 'generalAspects';
  aditional_info_general_aspects: string;
  status: boolean;
}

export interface CrossCuttingIssues {
  type: 'transversalIssues';
  projectDescription: Description[];
  status: boolean;
}

interface Description {
  title: string | null;
  description: string | null;
  agreement: string | null;
  id: number;
  status: boolean;
}

interface Agreements {
  type: 'agreements';
  status: boolean;
}

interface Issues {
  type: 'issues';
  aditional_info_other_issues: string;
  status: boolean;
}

interface EffectivenessAndSustainability {
  type: 'developmentAndSustainability';
  aditional_info_dev_and_sustainability: string;
  status: boolean;
}

interface Close {
  type: 'close';
  aditional_info_close: string;
  status: boolean;
}

interface Annexes {
  type: 'annexes';
  status: boolean;
}

export type MemoryHelpSection =
  | GeneralData
  | AspectsGenerals
  | CrossCuttingIssues
  | Agreements
  | Issues
  | EffectivenessAndSustainability
  | Close
  | Annexes;

type Status = boolean | null;

/*--- Context types ---*/
export interface MemoryHelpContextType {
  formState: MemoryHelpSection[];
  errors: Record<string, string>;
  isFormComplete: boolean;
  showConfirmContent: boolean;
  agreementsData: AgreementData;
  fileRevision: FileRevisionData | null;
  isUserUploaded: boolean;
  currentReview: number | null;
  loading: boolean | null;
  loadingHelpFile: boolean | null;
  optionalFields: string[];
  visitedTabs: number[];
  userCanEditRevision: boolean | null;
  userCanEditRevisionAccordances: boolean | null;
  loadingHelpError: any;
  triggerGetFiles: () => void;
  updateErrors: (field: string, stage: string, isValid: boolean) => void;
  generateUniqueId: () => number;
  getStatusArray: () => Status[];
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currentTabId: string) => void;
  onTabClickDynamicInputs: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  onTabClick: (currentTabIndex: number) => void;
  setFormState: React.Dispatch<React.SetStateAction<MemoryHelpSection[]>>;
  setIsFormComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmContent: React.Dispatch<React.SetStateAction<boolean>>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  setIsUserUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentReview: React.Dispatch<React.SetStateAction<number | null>>;
  setTrigger: React.Dispatch<React.SetStateAction<any>>;
  setAttachmentTrigger: React.Dispatch<React.SetStateAction<any>>;
  setPreviousView: React.Dispatch<React.SetStateAction<boolean | null>>;
  validateFormComplete: (prev: any) => void;
  setOptionalFields: React.Dispatch<React.SetStateAction<string[]>>;
  handleFileChange: (file: File) => void;
  refetch: () => void;
}

/*--- useAgreementData hook type ---*/
export interface AgreementData {
  dataRevision: DataRevisionProps;
  revisionHelpMemory: MemoryHelpSection[];
  status: string;
}

interface DataRevisionProps {
  startDate: string;
  endDate: string;
  revisionNumber: string;
  year: string;
  totalProjects: string;
  projectsNumber: number;
  approveD_AMOUNT: string;
  pendinG_DISBURSEMENT_AMOUNT: string;
  actuaL_PROJECTION_AMNT_USEQ: string;
  disburseD_AMNT_USEQ: string;
  closingyear: string;
  alerT_OPER_CNT: string;
  probleM_OPER_CNT: string;
}

/*--- File revision data ---*/
interface FileRevisionData {
  pdfBlob: Blob | null;
  wordBlob: Blob | null;
  pdfFile: string | null;
}

export interface UseGetMemoryHelpFileReturn {
  fileRevision: FileRevisionData;
  loadingHelpFile: boolean;
  loadingHelpError: Error | null;
  triggerFileFetch: () => void;
}

/*--- type guard ---*/
// Verify if a MemoryHelpSection object is a CrossCuttingIssues object
export function isCrossCuttingIssues(section: MemoryHelpSection): section is CrossCuttingIssues {
  return section.type === 'transversalIssues';
}

/*--- error types ---*/
export interface Errors {
  [key: string]: string;
}

/*--- Memory help modal types ---*/
export interface MemoryHelModalpProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedFile: any;
}

/*--- Memory help return modal types ---*/
export interface MemoryHelpReturnModalpProps {
  isOpen: boolean;
  navigatePath: string;
  closeModal: () => void;
  setVisibleComponent: (path: string) => void;
}

/*--- file uploader types ---*/
export interface FileUploaderProps {
  onFileAccepted: (file: File) => void;
  onFileDeleted: () => void;
}

/*--- dynamic inputs types ---*/
export interface DynamicInputSetProps {
  id: number;
  onRemove: (id: number) => void;
}
