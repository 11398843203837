import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabTitle from './ManageAgreementsComponents/TabTitle';
import TabPanel from './ManageAgreementsComponents/TabPanel';
import CheckCurrentAgreements from './ManageAgreementsComponents/CheckCurrentAgreements/CheckCurrentAgreements';
import CheckSuggestionsAgreements from './ManageAgreementsComponents/CheckSuggestionsAgreements/CheckSuggestionsAgreements';
import CreateDraftNewAgreements from './ManageAgreementsComponents/CreateDraftNewAgreements/CreateDraftNewAgreements';
import SeeConsolidatedAgreements from './ManageAgreementsComponents/SeeConsolidatedAgreements/SeeConsolidatedAgreements';
import './ManageAgreements.scss';
import { ReactComponent as IconCheck } from '../../../assets/icons/u_check.svg';
import { ReactComponent as IconArrowLeft } from '../../../assets/icons/u_arrow-left.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/icons/u_arrow-right.svg';
import { ReactComponent as IconWarning } from '../../../assets/icons/u_warning.svg';
import { ReactComponent as IconBack } from '../../../assets/icons/u_arrow-left.svg';
import { ApproveAgreementsModal } from '../../Util/Modals/CurrentAgreementsModal/ApproveAgreementsModal';
import { SaveChangesModal } from '../../Util/Modals/CurrentAgreementsModal/SaveChangesModal';
import AlertSaveChanges from './ManageAgreementsComponents/AlertSaveChanges';
import { useTranslation } from 'react-i18next';
import { useAgreementsHelpContext } from '../../../context/AgreementsContext';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../../redux/actions/currentProject';
import { setAgreementsDataStatus } from '../../../api/projectApi';

const statusArray = ['Status One', 'Status Two', 'Status Three', 'Status Four'];
const targetDate = new Date('2024-07-20'); // Fecha de la revisión para activar el botón

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface Props {
  projectId: any;
  localRouter: (string) => void;
  visibleComponent: (boolean) => void;
}

function ManageAgreements({ projectId, localRouter, visibleComponent }: Props) {
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [completedTabs, setCompletedTabs] = React.useState<number[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [openModal, setOpenModal] = React.useState({
    isOpen: false,
    modalType: '',
  });
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);

  const { t } = useTranslation();

  const {
    hiddenButton,
    setCurrentProject,
    setPostAgreementsTrigger,
    postDataAgreementsValid,
    postDataAgreements,
    setPostDataAgreementsValid,
  } = useAgreementsHelpContext();

  useEffect(() => {
    setCurrentProject(projectId);
    dispatch(getProject(projectId));
  }, [projectId]);

  useEffect(() => {
    const currentDate = new Date();
    if (currentDate >= targetDate) setIsButtonDisabled(false);
  }, []);

  useEffect(() => {
    let timer;
    if (isAlertOpen) {
      timer = setTimeout(() => {
        setIsAlertOpen(false);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [isAlertOpen]);

  const handleChange = (event, newValue) => {
    if (
      (newValue === 1 && postDataAgreementsValid?.UpdateAccordances?.length > 0) ||
      (newValue === 2 && postDataAgreementsValid?.UpdateAccordances?.length > 0)
    ) {
      setCompletedTabs(prevCompletedTabs => {
        if (!prevCompletedTabs.includes(value)) {
          return [...prevCompletedTabs, value];
        }
        return prevCompletedTabs;
      });
      handleOpenModal('saveChanges');
    } else {
      setCompletedTabs(prevCompletedTabs => {
        return [...prevCompletedTabs, value];
      });
    }
    setValue(newValue);
  };

  const handleNext = () => {
    const newValue = Math.min(value + 1, statusArray.length - 1);
    setValue(newValue);
    handleOpenModal('saveChanges');
  };

  const handlePrev = () => {
    setValue(prevValue => Math.max(prevValue - 1, 0));
  };

  const handleOpenModal = modalType => {
    setOpenModal({ isOpen: true, modalType });
  };

  const handleCloseModal = () => {
    setOpenModal({ isOpen: false, modalType: '' });
    setIsAlertOpen(false);
    setPostDataAgreementsValid({
      projectId: projectId,
      AccordanceManagements: [],
      UpdateAccordances: [],
    });
  };

  const handleConfirmChange = async () => {
    // Lógica para confirmar el cambio
    const accordanceState = postDataAgreements.filter(data => data.agreementManagement || data.accordanceState === 0);

    const filterData = accordanceState?.map(data => {
      if (data.agreementManagement && data.accordanceState === 2) {
        return {
          AccordanceId: data.id,
          AccordanceState: data.accordanceState,
          ShowAccordanceManagement: true,
          agreementManagement: data.agreementManagement,
        };
      } else {
        return {
          AccordanceId: data.id,
          AccordanceState: data.accordanceState,
          ShowAccordanceManagement: false,
          agreementManagement: false,
        };
      }
    });

    const postStructure = {
      projectId: projectId,
      AccordanceManagements: [],
      UpdateAccordances: filterData,
      projectIsClose: true,
    };

    await setAgreementsDataStatus(postStructure);
    setPostAgreementsTrigger(prev => !prev);
    handleCloseModal();
  };

  const handleSaveChangesClick = async () => {
    await setAgreementsDataStatus(postDataAgreementsValid);

    setPostAgreementsTrigger(prev => !prev);
    setIsAlertOpen(true); // Muestra el AlertSaveChanges al hacer clic en "Guardar Cambios"
  };

  const handleBack = () => {
    localRouter('operationChief');
    visibleComponent(true);
  };

  const dataTitle = currentReview?.projects.find(data => data.id === projectId);

  return (
    <section className="reviwes-agreements">
      <div className="display-header">
        <button onClick={() => handleBack()} className="btn btn-primary-text">
          <IconBack />
          {t('memory_help.button_back')}
        </button>
        <div>
          <h2>
            {t('new_manage_agreements')} <span>{currentReview.title}</span>
          </h2>

          <p>
            <b>{dataTitle.code}</b> {`${t('loan')[0].toUpperCase() + t('loan').substring(1)}:`} {dataTitle.loanNumber}
          </p>
        </div>
      </div>
      <div className="header">
        <div className="text">{t('new_instruction_accordance')}</div>
        <div className="button">
          {isAlertOpen && <AlertSaveChanges />}
          <button
            className="btn btn-primary"
            onClick={handleSaveChangesClick}
            disabled={postDataAgreementsValid?.UpdateAccordances?.length === 0}
          >
            {t('save_changes')}
          </button>
        </div>
      </div>
      <div className="tabs-content">
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          className="tabs-title"
        >
          {statusArray.map((status, index) => (
            <Tab
              key={index}
              label={
                <>
                  <TabTitle index={index} />
                  {completedTabs.includes(index) && <IconCheck />}
                </>
              }
              className={completedTabs.includes(index) ? 'completed-tab' : ''}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        <div className="tab-panel">
          <TabPanel value={value} index={0}>
            <CheckCurrentAgreements projectId={projectId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CheckSuggestionsAgreements projectId={projectId} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CreateDraftNewAgreements projectId={projectId} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <SeeConsolidatedAgreements projectIdDraft={projectId} />
          </TabPanel>
          <div className="footer-tab-content">
            <div className="steps">
              <div className="steps-info">
                {t('new_step')} {value + 1} {t('new_of')} {statusArray.length}
              </div>
              <div className="steps-button">
                <button className="btn btn-sm btn-primary-text" onClick={handlePrev} disabled={value === 0}>
                  <IconArrowLeft /> {t('new_previou')}
                </button>
                <button
                  className="btn btn-sm btn-primary-text"
                  onClick={handleNext}
                  disabled={value === statusArray.length - 1}
                >
                  {t('next')} <IconArrowRight />
                </button>
                {value === 3 && (
                  <button
                    disabled={!hiddenButton}
                    className="btn btn-sm btn-primary"
                    onClick={() => handleOpenModal('approveAgreements')}
                  >
                    {t('new_approve_agreement')}
                  </button>
                )}
              </div>
            </div>
            {value === 3 && isButtonDisabled && (
              <div className="alert">
                <p>
                  <IconWarning /> {t('new_enabled_button_accordance')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {openModal.isOpen && openModal.modalType === 'saveChanges' && (
        <SaveChangesModal
          isOpen={openModal.isOpen}
          closeModal={handleCloseModal}
          onConfirm={() => {
            handleSaveChangesClick();
            handleCloseModal();
          }}
        />
      )}
      {openModal.isOpen && openModal.modalType === 'approveAgreements' && (
        <ApproveAgreementsModal
          isOpen={openModal.isOpen}
          closeModal={handleCloseModal}
          onConfirm={() => {
            handleConfirmChange();
            handleBack();
          }}
        />
      )}
      {/* Activar para cuando los acuerdos estén vencidos */}
      {/* {openModal.isOpen && openModal.modalType === 'pendingUpdates' && (
        <PendingUpdatesModal
          isOpen={openModal.isOpen}
          closeModal={handleCloseModal}
        />
      )} */}
    </section>
  );
}

export default ManageAgreements;
