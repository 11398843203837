import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from '../Styles';
import './NewAccordance.scss';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import NewAccordanceWithTabs from './NewAccordanceWithTabs';
import NewAccordanceForm from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceForm';
import { AccordanceType } from '../../../../models/AccordanceType';
import ReviewFormHeader from '../../../Reviews/ReviewFormHeader/ReviewFormHeader';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import BaseModal from '../BaseModal';
import { initialState, NewAccordanceContext } from './context/NewAccordanceContext';
import MultiAccordances from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/MultiAccordances/MultiAccordances';

type Props = {
  isOpen: boolean;
  closeModal: any;
  currentReview?: any;
  canCreateInterGroupAccordances?: boolean;
  canCreateMonitoringAccordances?: boolean;
  canCreatePortfolioReviewAccordances?: boolean;
  accordanceCreateRevision?: boolean;
  criticalDataForm?: {};
  isSuggesttion?: boolean;
};

const NewAccordance = (props: Props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, currentReview, accordanceCreateRevision, criticalDataForm, isSuggesttion } = props;
  const [errorsMessage, setErrorsMessage] = useState<boolean>(false);
  const { state, setState } = useContext(NewAccordanceContext);
  const { bundleAccordances, possibleActionsList, supervisionActionList, accordanceFormType } = state;

  const resetContextCLose = (value?: any) => {
    setState({ ...initialState });
    closeModal(value);
  };

  return (
    <>
      <BaseModal
        shouldCloseOnOverlayClick={false}
        isOpen={isOpen}
        onRequestClose={e => disableEscKey(e, resetContextCLose)}
        style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
        className="modal-new-accordance-content"
      >
        <div className="modal-new-accordance-container">
          <div className="close-cross-container">
            <CloseCrossButton handleClick={() => resetContextCLose(false)} />
          </div>
          {currentReview ? (
            <>
              {errorsMessage && (
                <ErrorMessage
                  customStyle={{ marginLeft: -21, width: 622 }}
                  alertMessage={t('could_not_create_accordance')}
                  descriptionMessage={t('please_check_required_fields')}
                />
              )}
              <ReviewFormHeader />

              <NewAccordanceForm
                close={value => resetContextCLose(value)}
                accordanceFormType={AccordanceType.Revision}
                displayGenericErrorsMessage={value => setErrorsMessage(value)}
                review={currentReview}
              />
            </>
          ) : bundleAccordances.length ? (
            <div className="new-accordance-form-container">
              <MultiAccordances
                bundleAccordances={bundleAccordances}
                possibleActionsList={possibleActionsList}
                accordanceFormType={accordanceFormType}
                supervisionActionList={supervisionActionList}
                closeModal={resetContextCLose}
                canCreateInterGroupAccordances={props.canCreateInterGroupAccordances}
                canCreateMonitoringAccordances={props.canCreateMonitoringAccordances}
                canCreatePortfolioReviewAccordances={props.canCreatePortfolioReviewAccordances}
              />
            </div>
          ) : (
            <NewAccordanceWithTabs
              isSuggesttion={isSuggesttion}
              accordanceCreateRevision={accordanceCreateRevision}
              criticalDataForm={criticalDataForm}
              close={resetContextCLose}
              canCreateInterGroupAccordances={props.canCreateInterGroupAccordances}
              canCreateMonitoringAccordances={props.canCreateMonitoringAccordances}
              canCreatePortfolioReviewAccordances={props.canCreatePortfolioReviewAccordances}
            />
          )}
        </div>
      </BaseModal>
    </>
  );
};

export default NewAccordance;
