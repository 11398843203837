import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ReactComponent as IconSort } from '../../../../../assets/icons/u_sort.svg';
import { ReactComponent as IconAlertCheck } from '../../../../../assets/icons/u_alert-check.svg';
import NewAccordance from '../../../../Util/Modals/NewAccordance/NewAccordance';
import { useSelector } from 'react-redux';
import Project from '../../../../../models/Project';
import { useTranslation } from 'react-i18next';

interface Row {
  descripcion: string;
  temaCritico: string;
  id: number;
  existCriticalIssue: boolean;
}

interface CheckSuggestionsAgreementsTablesProps {
  rows: Row[];
  setGetAgreementsTrigger: (boolean) => void;
}

const CheckSuggestionsAgreementsTables: React.FC<CheckSuggestionsAgreementsTablesProps> = ({
  rows,
  setGetAgreementsTrigger,
}) => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const [showNewAccordanceModal, setShowNewAccordanceModal] = useState(false);

  const [criticalDataForm, setCriticalDataForm] = useState<{}>({});

  const { t } = useTranslation();

  const setModalAndCriticalData = (state: boolean, id: number) => {
    setShowNewAccordanceModal(state);

    const dataFilter = rows.find(data => data.id === id);
    const newStructure = {
      id: dataFilter?.id,
      label: dataFilter?.temaCritico,
      descripcion: dataFilter?.temaCritico,
      isSuggesttion: true,
    };

    setCriticalDataForm(newStructure);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="width-1">
                <div className="sort">
                  <p>{t('new_criterion')}</p>
                  <div>
                    <IconSort />
                  </div>
                </div>
              </TableCell>
              <TableCell className="width-2">
                <div className="sort">
                  <p>{t('description_of_the_critical_issue')}</p>
                  <div>
                    <IconSort />
                  </div>
                </div>
              </TableCell>

              <TableCell className="width-3">
                <p>{t('actions')}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="suggestion-table">
            {rows?.map(row => (
              <React.Fragment key={row.descripcion}>
                <TableRow className="row-issue">
                  <TableCell>{t(row.descripcion)}</TableCell>
                  <TableCell>{row.temaCritico}</TableCell>
                  <TableCell>
                    {!row.existCriticalIssue ? (
                      <button className="btn btn-primary" onClick={() => setModalAndCriticalData(true, row.id)}>
                        {t('new_create_draft')}
                      </button>
                    ) : (
                      <p className="display-text">
                        <IconAlertCheck />
                        <span>{t('new_draft_created')}</span>
                      </p>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NewAccordance
        isSuggesttion={true}
        accordanceCreateRevision={true}
        criticalDataForm={criticalDataForm}
        isOpen={showNewAccordanceModal}
        closeModal={() => {
          setShowNewAccordanceModal(false);
          setGetAgreementsTrigger(prev => !prev);
        }}
        canCreateInterGroupAccordances={currentProject.canCreateInterGroupAccordances}
        canCreateMonitoringAccordances={currentProject.canCreateMonitoringAccordances}
        canCreatePortfolioReviewAccordances={currentProject.canCreatePortfolioReviewAccordances}
      />
    </>
  );
};

export default CheckSuggestionsAgreementsTables;
