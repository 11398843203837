import React, { useEffect } from 'react';
import './NewAccordanceForm.scss';
import Comments from '../../../../Util/Comments/Comments';
import { AccordanceType } from '../../../../../models/AccordanceType';
import { newAccordanceComment, deleteAccordanceComment } from '../../../../../api/accordanceApi';
import { isDraftOrPending } from '../NewAccordanceForm/NewAccordanceFormUtil';
import SelectOption from '../../../../../models/SelectOption';
import SingleInputs from './SingleInputs';
import SharedInputs from './SharedInputs';
import { useTranslation } from 'react-i18next';

type Props = {
  criticalIssueList: any;
  criticalIssue: any;
  handleCriticalIssueChange: any;
  formErrors: any;
  category: any;
  possibleActionsList: any;
  possibleAction: any;
  handlePossibleActionChange: any;
  expirationDate: any;
  handleExpirationDate: Function;
  responsible: any;
  handleIemSelected: Function;
  projectId: number;
  filesList: any;
  addFileToList: Function;
  removeFileFromList: Function;
  editAccordanceData?: any;
  closeParentModal: Function;
  accordanceType: AccordanceType;
  handleDescription: any;
  description: string;
  revisionDate: any;
  criticalIssueDescription: string;
  handleCriticalIssueDescription: Function;
  accordanceState?: number;
  supervisionActionList: any;
  handleSupervisionActionChange: any;
  supervisionAction: any;
  setFiles: any;
  accordanceDate: any;
  handleAccordanceDate: Function;
  setFormErrors: any;
  setRevisionDate: any;
  setExpirationDate: any;
  setSemesterOption: any;
  semesterOption: SelectOption;
  accordanceFormType: AccordanceType;
  definedAgreement: any;
  linkedProduct: any;
  rootCauseOfCritical: any;
  rootCause: any;
  handleDefinedAgreementChange: Function;
  handleLinkedProduct: Function;
  handleRootCauseOfCritical: Function;
  handleRootCause: Function;
  accordanceDefinitionForm: any;
  rootCauseCategoriesForm: any;
  rootCauseForm: any;
  productForm: any;
  validateTextareaCritical: any;
  validateTextareaDescription: any;
  issuggestedAgreement: boolean;
};

export const displayLabel = (option: any) => {
  return option.label || '';
};

export const CommonInputs = (props: Props) => {
  const {
    criticalIssueList,
    criticalIssue,
    handleCriticalIssueChange,
    formErrors,
    category,
    possibleActionsList,
    possibleAction,
    handlePossibleActionChange,
    expirationDate,
    handleExpirationDate,
    responsible,
    handleIemSelected,
    filesList,
    addFileToList,
    removeFileFromList,
    editAccordanceData,
    accordanceType,
    handleDescription,
    description,
    revisionDate,
    criticalIssueDescription,
    handleCriticalIssueDescription,
    accordanceState,
    supervisionActionList,
    handleSupervisionActionChange,
    supervisionAction,
    setFiles,
    accordanceDate,
    handleAccordanceDate,
    setFormErrors,
    setRevisionDate,
    setExpirationDate,
    setSemesterOption,
    semesterOption,
    accordanceFormType,
    definedAgreement,
    linkedProduct,
    rootCauseOfCritical,
    rootCause,
    handleDefinedAgreementChange,
    handleLinkedProduct,
    handleRootCauseOfCritical,
    handleRootCause,
    accordanceDefinitionForm,
    rootCauseCategoriesForm,
    rootCauseForm,
    productForm,
    validateTextareaCritical,
    validateTextareaDescription,
    issuggestedAgreement,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (editAccordanceData) {
      setFiles([...editAccordanceData.accordanceFiles]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SharedInputs
        isMulti={false}
        criticalIssueList={criticalIssueList}
        criticalIssue={criticalIssue}
        handleCriticalIssueChange={handleCriticalIssueChange}
        formErrors={formErrors}
        category={category}
        expirationDate={expirationDate}
        revisionDate={revisionDate}
        criticalIssueDescription={criticalIssueDescription}
        handleCriticalIssueDescription={handleCriticalIssueDescription}
        setFormErrors={setFormErrors}
        setRevisionDate={setRevisionDate}
        setExpirationDate={setExpirationDate}
        setSemesterOption={setSemesterOption}
        semesterOption={semesterOption}
        accordanceFormType={accordanceFormType}
        accordanceDate={accordanceDate}
        handleAccordanceDate={handleAccordanceDate}
        definedAgreement={definedAgreement}
        linkedProduct={linkedProduct === null ? [] : linkedProduct}
        handleDefinedAgreementChange={handleDefinedAgreementChange}
        handleLinkedProduct={handleLinkedProduct}
        accordanceDefinitionForm={accordanceDefinitionForm}
        productForm={productForm}
        validateTextareaCritical={validateTextareaCritical}
        //
        expirationDateSingle={expirationDate}
        handleExpirationDateSingle={handleExpirationDate}
        handleDescriptionSingle={handleDescription}
        descriptionSingle={description}
        validateTextareaDescriptionSingle={validateTextareaDescription}
        responsibleSingle={responsible}
        handleIemSelectedSingle={handleIemSelected}
        editAccordanceDataSingle={editAccordanceData}
        accordanceStateSingle={accordanceState}
      />

      <SingleInputs
        isMulti={false}
        criticalIssue={criticalIssue}
        formErrors={formErrors}
        possibleActionsList={possibleActionsList}
        possibleAction={possibleAction}
        handlePossibleActionChange={handlePossibleActionChange}
        expirationDate={expirationDate}
        handleExpirationDate={handleExpirationDate}
        responsible={responsible}
        handleIemSelected={handleIemSelected}
        filesList={filesList}
        addFileToList={addFileToList}
        removeFileFromList={removeFileFromList}
        editAccordanceData={editAccordanceData}
        accordanceType={accordanceType}
        handleDescription={handleDescription}
        description={description}
        revisionDate={revisionDate}
        accordanceState={accordanceState}
        supervisionActionList={supervisionActionList}
        handleSupervisionActionChange={handleSupervisionActionChange}
        supervisionAction={supervisionAction}
        rootCauseOfCritical={rootCauseOfCritical}
        rootCause={rootCause}
        handleRootCauseOfCritical={handleRootCauseOfCritical}
        handleRootCause={handleRootCause}
        rootCauseCategoriesForm={rootCauseCategoriesForm}
        rootCauseForm={rootCauseForm}
        validateTextareaDescription={validateTextareaDescription}
        accordanceDate={accordanceDate}
        //
        criticalIssueListShared={criticalIssueList}
        criticalIssueShared={criticalIssue}
        handleCriticalIssueChangeShared={handleCriticalIssueChange}
        linkedProductShared={linkedProduct === null ? [] : linkedProduct}
        handleLinkedProductShared={handleLinkedProduct}
        productFormShared={productForm}
        criticalIssueDescriptionShared={criticalIssueDescription}
        handleCriticalIssueDescriptionShared={handleCriticalIssueDescription}
        validateTextareaCriticalShared={validateTextareaCritical}
        issuggestedAgreement={issuggestedAgreement}
      />

      {editAccordanceData && (
        <>
          <Comments
            comments={editAccordanceData.accordanceComments || []}
            submitComment={comment => newAccordanceComment(editAccordanceData.id, comment)}
            submitDeleteComment={commentId => deleteAccordanceComment(editAccordanceData.id, commentId)}
            canDeleteComments={isDraftOrPending(accordanceState)}
          />
        </>
      )}
    </>
  );
};
