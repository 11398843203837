import React, { useEffect, useState } from 'react';
import CheckCurrentAgreementsTable from './CheckCurrentAgreementsTable';
import { useAgreementsHelpContext } from '../../../../../context/AgreementsContext';
import { useTranslation } from 'react-i18next';
import { useGetAgreements } from '../../../../../hooks/useGetAgreements';
import { AgreementStatusStrings } from '../../../../../enums/accordanceStateEnum';
import { useSelector } from 'react-redux';
import SpinningLoader from '../../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';

export default function CheckCurrentAgreements({ projectId }) {
  const { t } = useTranslation();
  const [selectedCount, setSelectedCount] = useState(0);
  const [dataValid, setDataValid] = useState<any>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const getCurrentReview = useSelector<any, any>(state => state.currentReview);

  const { id: idReview } = getCurrentReview;

  const {
    getAgreementsTrigger,
    setGetAgreementsTrigger,
    extractAgreementsLength,
    postAgreementsTrigger,
    setPostAgreementsTrigger,
  } = useAgreementsHelpContext();

  const { agreementsData, agreementsLoading } = useGetAgreements(
    projectId,
    AgreementStatusStrings.VALID,
    idReview,
    getAgreementsTrigger,
  );

  const handleSelectionChange = count => {
    setSelectedCount(count);
  };

  const handleSetState = () => {
    setDataValid(agreementsData);
  };

  useEffect(() => {
    handleSetState();
  }, [agreementsData, editModal]);

  useEffect(() => {
    if (editModal || postAgreementsTrigger) {
      setTimeout(() => {
        setGetAgreementsTrigger(!getAgreementsTrigger);
      }, 1000);
    } else {
      setTimeout(() => {
        setGetAgreementsTrigger(false);
      }, 1000);
    }
    setPostAgreementsTrigger(false);
  }, [editModal, postAgreementsTrigger]);

  return (
    <div className="content-tabs">
      <h3>1. {t('new_review_current_agreements')}</h3>
      <div className="text-box">
        <p>{t('new_update_the_status')}</p>
      </div>
      {agreementsLoading ? (
        <div className="content-loader">
          <SpinningLoader />
        </div>
      ) : (
        <div className="table-check-current">
          <CheckCurrentAgreementsTable
            onSelectionChange={handleSelectionChange}
            data={dataValid}
            fnEditModal={setEditModal}
            projectId={projectId}
          />
        </div>
      )}

      <div className="text-footer">
        <p>
          <strong>
            {extractAgreementsLength} {t('new_current_agreements')}
          </strong>{' '}
          {t('new_selected_for_inclusion_in_portfolio_review')}
        </p>
      </div>
    </div>
  );
}
