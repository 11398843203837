/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Errors, MemoryHelpSection } from '../components/MemoryHelp/models';
import useAgreementsData from '../hooks/useAgreementsData';
import useGetMemoryHelpFile from '../hooks/useGetMemoryHelpFile';
import { useSelector } from 'react-redux';
import useSetMemoryHelpFileAttachment from '../hooks/useSetMemoryHelpFileAttatchment';
import { MemoryHelpRevisionStatus } from '../components/MemoryHelp/MemoryHelpRevisionStatus';
import { defaultValue, initialStateMemoryHelp, validateField } from '../components/MemoryHelp/utils';
import { RolesIdEnum } from '../models/RolesIdEnum';

const MemoryHelpContext = createContext(defaultValue);
export const useFormMemoryHelpContext = () => useContext(MemoryHelpContext);

export const MemoryHelpProvider = ({ children }) => {
  const [errors, setErrors] = useState<Errors>({});
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showConfirmContent, setShowConfirmContent] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [userCanEditRevision, setUserCanEditRevision] = useState<boolean | null>(null);
  const [userCanEditRevisionAccordances, setUserCanEditRevisionAccordances] = useState<boolean | null>(null);
  const [currentReview, setCurrentReview] = useState<number | null>(null);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [attachmentTrigger, setAttachmentTrigger] = useState<boolean>(false);
  const [isUserUploaded, setIsUserUploaded] = useState(false);
  const [previousView, setPreviousView] = useState<boolean | null>(null);
  const [optionalFields, setOptionalFields] = useState<string[]>([]);
  const [visitedTabs, setVisitedTabs] = useState<number[]>([]);
  const [, setLanguage] = useState<string>('');
  const [currentCountry, setCurrentCountry] = useState<string>('');
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [userIsDelegate, setUserIsDelgate] = useState<boolean | null>(null);

  const refRefetch = useRef(false);

  const { role } = useSelector<any, any>(state => state.user);
  const isDelegate = useSelector<any, any>(state => state.reviewsDelegate.isDelegate);

  const getCurrentReview = useSelector<any, any>(state => state.currentReview);
  const getCurrentReviewID = getCurrentReview?.id ?? null;

  const getInitialUserData = useSelector<any, any>(state => state.user);
  const currentLanguage = getInitialUserData.language.encodedName;

  const getCurrentCountry = getInitialUserData?.country?.name;

  // Get revisions main data
  const { agreementsData, loading } = useAgreementsData(currentReview, trigger);
  const shouldFetchFileData = agreementsData?.status !== 'denied';
  const currentReviewUpdate = shouldFetchFileData ? currentReview : null;

  // Get PDF and Word files
  const { fileRevision, loadingHelpFile, loadingHelpError, triggerFileFetch } = useGetMemoryHelpFile(
    currentReviewUpdate,
    shouldFetchFileData,
  );

  // Set PDF files
  const { handleFileChange } = useSetMemoryHelpFileAttachment(
    currentReviewUpdate,
    attachmentTrigger,
    shouldFetchFileData,
  );

  const triggerGetFiles = useCallback(() => triggerFileFetch(), [triggerFileFetch]);

  /** MAIN STATE
   * Initializes the form state. If the section type is 'transversalIssues' and the project description is empty,
   * it sets a default project description with a unique ID.
   */
  const [formState, setFormState] = useState(() =>
    initialStateMemoryHelp.map(section => {
      if (section.type === 'transversalIssues' && section.projectDescription.length === 0) {
        return {
          ...section,
          projectDescription: [{ id: generateUniqueId(), title: '', description: '', agreement: '', status: false }],
        };
      }
      return section;
    }),
  );

  /**
   * Generates a unique ID based on the current timestamp.
   * @returns {number} The unique ID.
   */
  const generateUniqueId = () => Date.now();

  /**
   * Refetches the data if conditions are met. It prevents multiple fetches within a short period.
   */
  const refetch = () => {
    if (shouldFetchFileData && !refRefetch.current) {
      setTrigger(prev => !prev);
      refRefetch.current = true;
      setTimeout(() => {
        refRefetch.current = false;
      }, 500);
    }
  };

  /**
   * Merges data from the endpoint with the initial state.
   * @param {Array} endpointData - The data received from the endpoint.
   * @param {Array} initialState - The initial state of the form.
   * @returns {Array} The merged data.
   */
  const mergeDataFromEndpoint = (endpointData, initialState) => {
    if (!endpointData || endpointData.length === 0)
      return initialState.map(section => ({
        ...section,
        projectDescription:
          section.projectDescription?.map(project => ({
            ...project,
            title: '',
            description: '',
            agreement: '',
            status: false,
          })) || [],
        status: false,
        institution_borrower: '',
        objectives: '',
        inputOpt: '',
        criteria: '',
        institutions_review: '',
        aditional_info_general_data: '',
        aditional_info_general_aspects: '',
        aditional_info_other_issues: '',
        aditional_info_dev_and_sustainability: '',
        aditional_info_close: '',
      }));

    return initialState.map(section => {
      const dataFromEndpoint = endpointData.find(data => data.type === section.type);
      if (dataFromEndpoint) {
        let updatedSection = { ...section };

        Object.keys(section).forEach(key => {
          if (dataFromEndpoint.hasOwnProperty(key) && dataFromEndpoint[key] !== null) {
            updatedSection[key] = dataFromEndpoint[key];
          }
        });

        if (
          section.projectDescription &&
          Array.isArray(section.projectDescription) &&
          dataFromEndpoint.projectDescriptions
        ) {
          updatedSection.projectDescription = dataFromEndpoint.projectDescriptions.map(desc => ({
            ...desc,
            id: desc.idProjectDescription,
            status: desc.status,
            title: desc.title || '',
            description: desc.description || '',
            agreement: desc.agreement || '',
          }));
        }

        return updatedSection;
      }
      return section;
    });
  };

  /**
   * Updates the status of a section based on the validity of its fields.
   * @param {Object} section - The section to update.
   * @param {string} name - The name of the field being updated.
   * @param {string} value - The value of the field being updated.
   * @returns {Object} The updated section with the new status.
   */
  const updateSectionStatus = (section, name, value) => {
    const updatedSection = { ...section, [name]: value };
    const fieldsToCheck = Object.keys(updatedSection).filter(
      key => !['status', 'type', 'projectDescription'].includes(key),
    );
    const allFieldsValid = fieldsToCheck.every(key => validateField(updatedSection[key], key, optionalFields));

    return { ...updatedSection, status: allFieldsValid };
  };

  /**
   * Handles input change events. Validates the input and updates the form state and error state accordingly.
   * @param {Object} e - The event object.
   * @param {string} currentTabId - The ID of the current tab.
   */

  const onInputChange = (e, currentTabId) => {
    const { name, value } = e.target;

    if (!name || !currentTabId) return; // Ensure name and currentTabId are not undefined
    const isValid = validateField(value, name, optionalFields);

    updateErrors(name, currentTabId, isValid);

    setFormState(prevState =>
      prevState.map((section, index) => {
        if (section.type === 'agreements' || section.type === 'annexes') return section;

        const sectionWithType = section as MemoryHelpSection;

        if (
          (sectionWithType.type === 'agreements' && index === 3) ||
          (sectionWithType.type === 'annexes' && index === 7)
        ) {
          if (!sectionWithType.status) return { ...sectionWithType, status: true };

          return sectionWithType;
        }

        if (section.type === currentTabId && isInitialized) {
          const updatedSection = updateSectionStatus(section, name, value);
          return updatedSection;
        }
        validateFormComplete(formState);
        return section;
      }),
    );
  };

  const validateFormComplete = (updatedFormState: MemoryHelpSection[]) => {
    const isFormValid = updatedFormState.every(section => section.status);
    const hasErrors = Object.keys(errors).length > 0;
    setIsFormComplete(isFormValid && !hasErrors);
  };

  /**
   * Updates the local storage with the new visited tabs and their statuses.
   * @param {Array} newVisitedTabs - The array of newly visited tab indices.
   * @param {Array} newStatusArray - The array of statuses for the tabs.
   */
  const updateLocalStorage = (newVisitedTabs, newStatusArray) => {
    if (!currentCountry || !currentReview) return; // Prevent storage if country or reviewNumber are null or empty

    const storageData = JSON.parse(localStorage.getItem('visitedTabs') || '[]');
    let countryData = storageData.find(country => country.country === currentCountry);
    if (!countryData) {
      countryData = { country: currentCountry, revisions: [] };
      storageData.push(countryData);
    }

    let revisionData = countryData.revisions.find(rev => rev.revisionNumber === currentReview);
    if (!revisionData) {
      revisionData = { revisionNumber: currentReview, tabs: {} };
      countryData.revisions.push(revisionData);
    }

    newVisitedTabs.forEach(tabIndex => {
      revisionData.tabs[tabIndex] = newStatusArray[tabIndex];
    });

    localStorage.setItem('visitedTabs', JSON.stringify(storageData));
  };

  /**
   * Handles tab click events. Updates the visited tabs state and the form state if certain conditions are met.
   * @param {number} currentTabIndex - The index of the current tab.
   */
  const onTabClick = useCallback(
    currentTabIndex => {
      setVisitedTabs(prev => Array.from(new Set([...prev, currentTabIndex])));
      if (currentTabIndex === 3 || currentTabIndex === 7) {
        setFormState(prevState =>
          prevState.map((section, index) => {
            if (index === currentTabIndex && !section.status) return { ...section, status: true };

            return section;
          }),
        );
      }
    },
    [currentReview, currentCountry],
  );

  /**
   * Handles dynamic input changes for project descriptions within a section.
   * @param {Object} e - The event object.
   * @param {number} projectId - The ID of the project being updated.
   */
  const onTabClickDynamicInputs = (e, projectId) => {
    const { name, value } = e.target;
    if (!name || !projectId) return;
    const fieldName = name.split('-')[0];

    setFormState(prevState =>
      prevState.map(section => {
        if (section.type === 'transversalIssues') {
          const updatedProjectDescription = section.projectDescription.map(project => {
            if (project.id === projectId) {
              const updatedProject = { ...project, [fieldName]: value };
              const isValid = validateField(value, fieldName, optionalFields);

              setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                if (isValid) {
                  delete newErrors[`${fieldName}-${projectId}`];
                } else {
                  newErrors[`${fieldName}-${projectId}`] = 'Campo obligatorio';
                }
                return newErrors;
              });

              const allFieldsValid = ['title', 'description', 'agreement'].every(field =>
                validateField(updatedProject[field], field, optionalFields),
              );
              return { ...updatedProject, status: allFieldsValid };
            }
            return project;
          });

          const allProjectsValid = updatedProjectDescription.every(project => project.status);
          return { ...section, projectDescription: updatedProjectDescription, status: allProjectsValid };
        }
        return section;
      }),
    );
  };

  /**
   * Validates all fields in the current state and updates the error state.
   * @param {Array} currentState - The current state of the form.
   */
  const validateAllFields = currentState => {
    let newErrors: Errors = {};
    currentState.forEach(section => {
      Object.keys(section).forEach(key => {
        if (
          section[key] === null &&
          typeof section[key] === 'string' &&
          !validateField(section[key], key, optionalFields) &&
          !optionalFields.includes(key)
        ) {
          newErrors[`${key}-${section.type}`] = 'Campo obligatorio';
        }
      });

      if (section.projectDescription) {
        section.projectDescription.forEach(project => {
          ['title', 'description', 'agreement'].forEach(field => {
            if (
              project[field] === null &&
              !validateField(project[field], field, optionalFields) &&
              !optionalFields.includes(field)
            ) {
              newErrors[`${field}-${project.id}`] = 'Campo obligatorio';
            }
          });
        });
      }
    });
    setErrors(newErrors);
  };

  /**
   * Gets the status array based on the form state and the visited tabs.
   * @returns {Array} The status array.
   */
  const getStatusArray = () =>
    formState.map((section, index) => {
      if (initialRender) {
        return section.status === true ? true : null;
      }
      return visitedTabs.includes(index) ? section.status : null;
    });

  /**
   * Updates the error state based on the validation of a field.
   * @param {string} name - The name of the field.
   * @param {string} currentTabId - The ID of the current tab.
   * @param {boolean} isValid - The validation result of the field.
   */
  const updateErrors = (name, currentTabId, isValid) => {
    if (!name || !currentTabId) return; // Ensure name and currentTabId are not undefined
    const errorKey = `${name}-${currentTabId}`;

    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      if (isValid) {
        delete newErrors[errorKey];
      } else if (!optionalFields.includes(name)) {
        newErrors[errorKey] = 'Campo obligatorio';
      }
      return newErrors;
    });
  };

  // Effect to update local storage when visited tabs or form state changes,
  // excluding the initial render to avoid unnecessary storage operations.
  useEffect(() => {
    const newStatusArray = getStatusArray();
    if (!initialRender) {
      updateLocalStorage(visitedTabs, newStatusArray);
    }
  }, [visitedTabs, formState, initialRender]);

  // Effect to set the current review to null initially and then to the current review ID
  // if the previous view is not set.
  useEffect(() => {
    // Only set currentReview to null if it does not have a valid value
    if (currentReview === null || currentReview === undefined) setCurrentReview(null);
    if (!previousView && getCurrentReviewID !== null) setCurrentReview(getCurrentReviewID);
  }, [getCurrentReviewID, previousView, currentReview]);

  // Effect to trigger refetching data when the current review changes.
  useEffect(() => {
    if (currentReview !== null) refetch();
  }, [currentReview]);

  // Effect to merge data from the endpoint with the form state when agreements data is available
  // and the current review is not null. Sets initialization and shows confirm content if needed.
  useEffect(() => {
    if (agreementsData && agreementsData.revisionHelpMemory && currentReview !== null) {
      if (agreementsData.revisionHelpMemory.length === 0) {
        setFormState(initialStateMemoryHelp);
      } else {
        const newState = mergeDataFromEndpoint(agreementsData.revisionHelpMemory, initialStateMemoryHelp);
        setFormState(newState);
      }
      setIsInitialized(true);
    }
  }, [agreementsData, currentReview]);

  // Effect to initialize form state by merging data from the endpoint and validating all fields
  // if agreements data is available and the form is not initialized yet.
  useEffect(() => {
    if (agreementsData && !isInitialized) {
      setFormState(prevState => {
        const newState = mergeDataFromEndpoint(agreementsData.revisionHelpMemory, prevState);
        validateAllFields(newState);
        setIsInitialized(true);
        return newState;
      });
    }
  }, [agreementsData, isInitialized]);

  useEffect(() => {
    if (agreementsData) {
      setShowConfirmContent(agreementsData.status === MemoryHelpRevisionStatus.Confirm);
    } else {
      setShowConfirmContent(false);
    }
  }, [agreementsData]);

  // Effect to check if the form is complete by verifying that all sections have a true status.
  useEffect(() => {
    setIsFormComplete(formState.filter(section => section.status !== null).every(section => section.status));
  }, [formState, errors]);

  // Effect to clean up errors by removing any undefined values, ensuring the error state is up-to-date.
  useEffect(() => {
    const cleanErrors = Object.entries(errors).reduce((acc, [key, value]) => {
      if (value !== undefined && key.indexOf('undefined') === -1) acc[key] = value; // ?? add validation to fix error
      return acc;
    }, {} as Errors);
    if (Object.keys(cleanErrors).length !== Object.keys(errors).length) setErrors(cleanErrors);
  }, [errors]);

  // Effect to load visited tabs and form state from local storage when current review or country changes.
  // Sets the initial render flag to false after loading.
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('visitedTabs') || '[]');
    const countryData = storedData.find(country => country.country === currentCountry);
    if (countryData) {
      const revisionData = countryData.revisions.find(rev => rev.revisionNumber === currentReview);
      if (revisionData) {
        setVisitedTabs(Object.keys(revisionData.tabs).map(tab => parseInt(tab)));
        setFormState(prevState =>
          prevState.map((section, index) => {
            if (revisionData.tabs[index] !== undefined) {
              if (revisionData.tabs[index] === true) {
                return { ...section, status: revisionData.tabs[index] };
              }
            }
            return section;
          }),
        );
      }
    }
    setInitialRender(false);
  }, [currentReview, currentCountry]);

  // Effect to set the current country when the getCurrentCountry function is available.
  useEffect(() => {
    if (getCurrentCountry) setCurrentCountry(getCurrentCountry);
  }, [getCurrentCountry]);

  // Effect to set the language when the initial user data is available.
  useEffect(() => {
    if (getInitialUserData) setLanguage(currentLanguage);
  }, [getInitialUserData]);

  // Effect to set if current user is delegate
  // Gets whether the user is a delegate or not
  useEffect(() => {
    setUserIsDelgate(isDelegate);
  }, [isDelegate]);

  // Effect to set if current user can edit revision
  // Validates if the user can edit revision (Operation chief or delegate)
  useEffect(() => {
    const canEditReview = role.id === RolesIdEnum.Chief_of_Operations || isDelegate;
    setUserCanEditRevision(canEditReview);
    const canEditReviewAccordances =
      role.id === RolesIdEnum.Chief_of_Operations ||
      role.id === RolesIdEnum.Team_Leader ||
      role.id === RolesIdEnum.Operations_Analyst ||
      role.id === RolesIdEnum.ProceduremenManager ||
      role.id === RolesIdEnum.ProceduremenManager ||
      role.id === RolesIdEnum.Financial_Specialist ||
      role.id === RolesIdEnum.Coordinator ||
      role.id === RolesIdEnum.Consultant ||
      role.id === RolesIdEnum.ESG_Reviewer ||
      role.id === RolesIdEnum.ESG_TeamMember ||
      role.id === RolesIdEnum.ESG_Primary_TeamMember ||
      role.id === RolesIdEnum.Alternate_Team_Leader ||
      role.id === RolesIdEnum.COS_pecialist ||
      role.id === RolesIdEnum.Managerial;
    setUserCanEditRevisionAccordances(canEditReviewAccordances);
  }, [userIsDelegate]);

  const value = useMemo(
    () => ({
      agreementsData,
      fileRevision,
      formState,
      errors,
      isFormComplete,
      showConfirmContent,
      userCanEditRevision,
      userCanEditRevisionAccordances,
      currentReview,
      isUserUploaded,
      optionalFields,
      visitedTabs,
      loading,
      loadingHelpFile,
      loadingHelpError,
      setFormState,
      setIsFormComplete,
      setErrors,
      setShowConfirmContent,
      setCurrentReview,
      setTrigger,
      setAttachmentTrigger,
      setIsUserUploaded,
      setPreviousView,
      setOptionalFields,
      onInputChange,
      onTabClickDynamicInputs,
      onTabClick,
      validateFormComplete,
      updateErrors,
      generateUniqueId,
      getStatusArray,
      handleFileChange,
      triggerGetFiles,
      refetch,
    }),
    [
      agreementsData,
      fileRevision,
      formState,
      errors,
      isFormComplete,
      showConfirmContent,
      userCanEditRevision,
      userCanEditRevisionAccordances,
      currentReview,
      isUserUploaded,
      optionalFields,
      visitedTabs,
      loading,
      loadingHelpFile,
      loadingHelpError,
    ],
  );

  return <MemoryHelpContext.Provider value={value}>{children}</MemoryHelpContext.Provider>;
};
