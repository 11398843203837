import React, { useCallback, useContext, useEffect, useState } from 'react';
import css from './TagFilter.module.scss';
import { useContainerDimensions } from '../../hooks/useContainerDimension';
import { ReactComponent as IconMultiply } from '../../assets/icons/u_multiply.svg';
import { mockObjFilter } from '../../mockData/mockObjFilter';
import { useDispatch } from 'react-redux';
import { setFilterIndicators } from '../../redux/actions/filterIndicators';
import { TagFilterContext } from '../../context/TagFilterContext';
import useFilters from '../../hooks/useFilters';

export const TagFilter = ({ tag }) => {
  const {
    filter,
    filterIndicators,
    filterAnalytics,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();
  const { containerRef } = useContainerDimensions(tag);
  const dispatch = useDispatch();
  const { deleteTagFromContext, optionsBySelect, labels } = useContext(TagFilterContext);

  const [tagSelected, setTagSelected] = useState(null);
  const [pass, setPass] = useState({
    region: false,
    country: false,
    sector: false,
    division: false,
    responsibles: false,
    participants: false,
    leader: false,
    attribiute: false,
  });

  // TODO: se cambia el tipado para evitar errores.
  let newCountryArr: any = optionsBySelect;

  const deleteTag = tag => {
    setTagSelected(tag);
    deleteTagFromContext(tag);
    setPass({
      region: newCountryArr?.regions?.some(elem => elem.label === tag),
      country: newCountryArr?.country?.some(elem => elem.label === tag),
      sector: newCountryArr?.sector?.some(elem => elem.label === tag),
      division: newCountryArr?.division?.some(elem => elem.label === tag),
      responsibles: newCountryArr?.responsibles?.some(elem => elem.label === tag),
      participants: newCountryArr?.participants?.some(elem => elem.label === tag),
      leader: newCountryArr?.teamLeader?.some(elem => elem.label === tag),
      attribiute: newCountryArr?.Attributes?.some(elem => elem.label === tag),
    });
  };

  /**
   * funcióne que dispacha segun la condición al redux con los elementos eliminados.
   */

  const deletedFilterIndicator = () => {
    pass?.region &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, regionId: newCountryArr.regions },
        }),
      );

    pass?.country &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, countryId: newCountryArr.country },
        }),
      );

    pass?.sector &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, sectors: newCountryArr.sector, divisionId: [] },
        }),
      );

    pass?.division &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, divisionId: newCountryArr.division },
        }),
      );

    pass?.responsibles &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, coReponsibleDivisions: newCountryArr.responsibles },
        }),
      );

    pass?.participants &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, participantDivisions: newCountryArr.participants },
        }),
      );

    pass?.leader &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, teamLeaders: newCountryArr.teamLeader },
        }),
      );

    pass?.attribiute &&
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: { ...filterIndicators.common, interestIssues: newCountryArr.Attributes },
        }),
      );

    setPass({
      region: false,
      country: false,
      sector: false,
      division: false,
      responsibles: false,
      participants: false,
      leader: false,
      attribiute: false,
    });
  };

  // TODO: se ejecuta al cambiar el valor de la variable "newCountryArr".

  useEffect(() => {
    deletedFilterIndicator();
  }, [tagSelected]);

  // useEffect(() => {
  //   if (labels.length === 0) {
  //     deletedFilterIndicator();
  //   }
  // }, [tagSelected]);

  return (
    <div ref={containerRef} className={css['c-tag-filter']}>
      <p>{tag}</p>
      <span onClick={() => deleteTag(tag)}>
        <IconMultiply />
      </span>
    </div>
  );
};
