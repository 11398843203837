import React, { useState } from 'react';
import AccordionTable from './SeeConsolidatedAccordion';
import { useTranslation } from 'react-i18next';
import Project from '../../../../../models/Project';
import { useSelector } from 'react-redux';

interface Props {
  projectIdDraft: number;
}

export default function SeeConsolidatedAgreements({ projectIdDraft }: Props) {
  const { t } = useTranslation();

  const currentProject = useSelector<any, Project>(state => state.currentProject);

  return (
    <div className="content-tabs">
      <h3>4. {t('new_consolidate_all_agreements')}</h3>
      <div className="text-box">
        <p>{t('new_consolidate_all_agreements_text')}</p>
      </div>
      <div>
        <AccordionTable projectIdDraft={projectIdDraft} currentProject={currentProject} />
      </div>
    </div>
  );
}
