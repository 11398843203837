import React, { useEffect, useState } from 'react';
import CheckSuggestionsAgreementsTables from './CheckSuggestionsAgreementsTables';
import { ReactComponent as IconStart } from '../../../../../assets/icons/u_star-ai.svg';
import { useTranslation } from 'react-i18next';
import { useAgreementsHelpContext } from '../../../../../context/AgreementsContext';
import { useGetAgreementsSuggestion } from '../../../../../hooks/useGetAgreementsSuggestion';
import SpinningLoader from '../../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { useSelector } from 'react-redux';
const tableData = [
  {
    title: 'Desempeño de salvaguardas ambientales y sociales insatisfactorio o parcialmente insatisfactorio',
    rows: [
      {
        critical_issue: 'Gestión de salvaguardas ambientales y sociales',
        description:
          'El desempeño de la gestión de salvaguardas ambientales y sociales fue evaluado como insatisfactorio o parcialmente insatisfactorio por el Banco, en la última revisión realizada por el equipo de ESG.',
        classifications: [
          { clasification: 'Plan de acción de salvaguardas ambientales y sociales' },
          { clasification: 'Clasificación del acuerdo 2' },
          { clasification: 'Clasificación del acuerdo 3' },
        ],
      },
    ],
  },
  {
    title: 'Plazo final de desembolso en los próximos 12 meses y ejecución menor al 90% de desembolsos',
    rows: [
      {
        critical_issue: 'Cierre del proyecto',
        description:
          'El plazo para último desembolso vigente del proyecto es (fecha que traiga el sistema) y se ha desembolsado el x% (del sistema) del monto BID vigente.',
        classifications: [
          { clasification: 'Extensión al plazo de último desembolso del programa' },
          { clasification: 'Cancelación parcial' },
        ],
      },
    ],
  },
];

export default function CheckSuggestionsAgreements({ projectId }) {
  const { t } = useTranslation();
  const [dataValid, setDataValid] = useState<any>();
  const getCurrentReview = useSelector<any, any>(state => state.currentReview);

  const { id: idReview } = getCurrentReview;

  const { getAgreementsTrigger, setGetAgreementsTrigger, extractAgreementsLength } = useAgreementsHelpContext();

  const { agreementsDataSuggestion, agreementsLoading } = useGetAgreementsSuggestion(
    projectId,
    getAgreementsTrigger,
    idReview,
  );

  const handleSetState = () => {
    setDataValid(agreementsDataSuggestion);
  };

  useEffect(() => {
    handleSetState();
  }, [agreementsDataSuggestion]);

  return (
    <div className="content-tabs">
      <h3>2. {t('new_agreement_suggestion')}</h3>
      <div className="text-box">
        <p>
          <IconStart /> {t('new_agreement_suggestion_text')}
        </p>
      </div>

      <div>
        {agreementsLoading ? (
          <div className="content-loader">
            <SpinningLoader />
          </div>
        ) : (
          <div className="table-check-current table-suggestions">
            <CheckSuggestionsAgreementsTables rows={dataValid} setGetAgreementsTrigger={setGetAgreementsTrigger} />
          </div>
        )}
      </div>
    </div>
  );
}
