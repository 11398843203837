import { useEffect, useRef, useState } from 'react';
import { getAgreementsDataSuggestion } from '../api/projectApi';

export const useGetAgreementsSuggestion = (projectId: number | null, trigger: any = null, revision) => {
  const [agreementsDataSuggestion, setAgreementsDataSuggestion] = useState<any>(null);
  const [agreementsLoading, setAgreementsLoading] = useState<boolean>(false);
  const [agreementsError, setAgreementsError] = useState<Error | null>(null);

  const previousAgreementId = useRef<number | null>(null);

  const fetchAgreementsInfo = async () => {
    if (projectId === null) return;

    setAgreementsLoading(true);
    try {
      const data = await getAgreementsDataSuggestion(projectId, revision);

      setAgreementsDataSuggestion(data);
      setAgreementsError(null);
      previousAgreementId.current = projectId;
    } catch (err) {
      console.error('Error al actualizar los acuerdos:', err);
      setAgreementsError(err as Error);
    } finally {
      setAgreementsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgreementsInfo();
  }, [trigger]);

  return { agreementsDataSuggestion, agreementsLoading, agreementsError };
};
