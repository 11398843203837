import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as IconArrow } from '../../../../../assets/icons/u_chevron-right-b.svg';
import { ReactComponent as IconSort } from '../../../../../assets/icons/u_sort.svg';
import { SelectAgreements } from './SelectAgreements';
import { useAgreementsHelpContext } from '../../../../../context/AgreementsContext';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import { useTranslation } from 'react-i18next';
import AccordanceDetail from '../../../../Util/Modals/AccordanceDetail/AccordanceDetail';
import useAccordanceDetailModalsState from '../../../../../hooks/useAccordanceDetailModalsState';
import { useSelector } from 'react-redux';
import User from '../../../../../models/User';
import { useEventContext } from '../../../../../context/EventContext';
import Project from '../../../../../models/Project';

enum AccordanceState {
  VALID = 2,
  COMPLETE = 3,
  CANCEL = 4,
}

const AGREEMENT_STATUS = AccordanceState.VALID;

interface originalDataProps {
  AccordanceId: number;
  AccordanceState: number;
  agreementManagement: boolean;
}

/**
 * Checks if a given date is expired.
 * Compares the provided date string with the current date.
 *
 * @param {string} dateString - The date to be checked in string format.
 * @returns {boolean} - Returns true if the date is in the past (expired), false otherwise.
 */
const isDateExpired = (dateString: string) => new Date(dateString) < new Date();

/**
 * Filters an array of agreements to only include those with active management.
 *
 * @param {any[]} data - The array of agreement data to be filtered.
 * @returns {any[]} - Returns a new array with only the items where `agreementManagement` is true.
 */
const filterAgreementsData = (data: any[]) => {
  return data.filter(item => item.agreementManagement === true);
};

const CheckCurrentAgreementsTable = ({ showCheckboxes = false, data, fnEditModal, projectId }: any) => {
  const { currentProject, setExtractAgreementsLength, setPostDataAgreementsValid, postDataAgreementsValid } =
    useAgreementsHelpContext();
  const { t } = useTranslation();
  const [rows, setRows] = useState<any[]>([]);
  const [updatePayload, setUpdatePayload] = useState({
    projectId: projectId,
    AccordanceManagements: [],
    UpdateAccordances: [] as originalDataProps[],
  });
  const currentProjectAgreements = useSelector<any, Project>(state => state.currentProject);
  const [originalData, setOriginalData] = useState<originalDataProps[]>([]);
  const [dataValid, setDataValid] = useState<any>();
  const [accordanceDetail, setAccordanceDetail] = useState<any>();
  const [catchIndex, setCatchIndex] = useState<any>();
  const stateProps = useAccordanceDetailModalsState();
  const {
    showModal,
    showDetail,
    hideModal,
    showJustificationModal,
    refreshBoard,
    changeStatus,
    setShowConfirm,
    setShowConfirmDeleteBundle,
  } = stateProps;

  const user = useSelector<any, User>(state => state.user);
  const { getDataEvent, setEventId, eventId, getDataValidity } = useEventContext();

  const handleCardClick = cardId => {
    setEventId(cardId);
  };

  useEffect(() => {
    if (data) {
      setCatchIndex(data?.map((data, i) => ({ id: i, status: null })));

      const dataToRender = data;

      const filterSelect = filterAgreementsData(data);

      if (typeof setExtractAgreementsLength === 'function') setExtractAgreementsLength(filterSelect.length);

      const formattedRows = dataToRender.map(item => ({
        id: item.id,
        description: item.description,
        issue: item.criticalIssue.name,
        date: renderDateOrSlash(item.expirationDate),
        status: AGREEMENT_STATUS,
        agreementManagement: item.agreementManagement,
        newDate: renderDateOrSlash(item?.newExpirationDate),
      }));
      setRows(formattedRows);

      const initialData = dataToRender.map(item => ({
        AccordanceId: item.id,
        AccordanceState: AGREEMENT_STATUS,
        agreementManagement: item.agreementManagement,
      }));
      setOriginalData(initialData);
    }
    setDataValid(data);
    setUpdatePayload({
      projectId: projectId,
      AccordanceManagements: [],
      UpdateAccordances: [] as originalDataProps[],
    });
  }, [data, showCheckboxes, showDetail]);

  useEffect(() => {
    if (eventId && currentProject) {
      getDataEvent();
      getDataValidity(currentProject);
    }
  }, [eventId]);

  /**
   * Check if the current row has been modified from its original state.
   * (Restored from the original behavior)
   */
  const hasChangedFromOriginal = (AccordanceId: number, updatedObject: any) => {
    const originalObject = originalData.find(item => item.AccordanceId === AccordanceId);
    if (!originalObject) return true;

    return (
      originalObject.AccordanceState !== updatedObject.AccordanceState ||
      originalObject.agreementManagement !== updatedObject.agreementManagement
    );
  };

  /**
   * Handle individual row checkbox change.
   * Modified to follow the original logic for updating rows.
   */
  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    const updatedRows = rows.map(row => (row.id === id ? { ...row, agreementManagement: isChecked } : row));
    setRows(updatedRows);

    setUpdatePayload(prev => {
      const updatedObject = {
        AccordanceId: id,
        AccordanceState: rows.find(row => row.id === id)?.status || AccordanceState.VALID,
        agreementManagement: isChecked,
      };

      const filteredAccordances = prev.UpdateAccordances.filter(acc => acc.AccordanceId !== id);

      if (hasChangedFromOriginal(id, updatedObject)) {
        return { ...prev, UpdateAccordances: [...filteredAccordances, updatedObject] };
      } else {
        return { ...prev, UpdateAccordances: filteredAccordances };
      }
    });
  };

  /**
   * Handle header checkbox change (controlling all row checkboxes).
   * Restored the original logic that updates all rows accordingly.
   */
  const handleHeaderCheckboxChange = (isChecked: boolean) => {
    const updatedRows = rows.map(row => ({ ...row, agreementManagement: isChecked }));
    setRows(updatedRows);

    setUpdatePayload(prev => {
      const updatedAccordances = updatedRows.map(row => ({
        AccordanceId: row.id,
        AccordanceState: row.status || AccordanceState.VALID,
        agreementManagement: isChecked,
      }));

      return { ...prev, UpdateAccordances: updatedAccordances };
    });
  };

  const handleStatusChangeInPayload = (index: number, newStatus: string) => {
    const updatedAccordanceId = rows[index].id;
    const updatedState = parseInt(newStatus, 10);

    setCatchIndex(prev => prev.map(data => (data.id === index ? { ...data, status: newStatus } : data)));

    const updatedRows = rows.map((row, idx) =>
      idx === index ? { ...row, status: updatedState, agreementManagement: newStatus !== '2' && false } : row,
    );
    setRows(updatedRows);

    setUpdatePayload(prev => {
      const updatedObject = {
        AccordanceId: updatedAccordanceId,
        AccordanceState: updatedState,
        agreementManagement: rows.find(row => row.id === updatedAccordanceId)?.agreementManagement || false,
      };

      const filteredAccordances = prev.UpdateAccordances.filter(acc => acc.AccordanceId !== updatedAccordanceId);

      if (hasChangedFromOriginal(updatedAccordanceId, updatedObject)) {
        return { ...prev, UpdateAccordances: [...filteredAccordances, updatedObject] };
      } else {
        return { ...prev, UpdateAccordances: filteredAccordances };
      }
    });
  };

  const showDetailModal = id => {
    setAccordanceDetail(dataValid.filter(data => data.id === id));
    showModal();
  };

  useEffect(() => {
    setPostDataAgreementsValid(updatePayload);
  }, [handleCheckboxChange, handleHeaderCheckboxChange, handleStatusChangeInPayload]);

  return (
    <>
      <TableContainer>
        <Table aria-label="agreements table">
          <TableHead>
            <TableRow>
              {!showCheckboxes && (
                <TableCell>
                  <Checkbox
                    indeterminate={
                      rows.some(row => row.agreementManagement) && !rows.every(row => row.agreementManagement)
                    }
                    checked={rows.length > 0 && rows.every(row => row.agreementManagement)}
                    onChange={e => {
                      const isChecked = e.target.checked;
                      handleHeaderCheckboxChange(isChecked);
                      {
                        /* Modificado: Se agrega la función handleHeaderCheckboxChange */
                      }
                    }}
                    inputProps={{ 'aria-label': 'select all agreements' }}
                    disabled={catchIndex?.filter(data => data.status !== null && data.status !== '2').length > 0}
                  />
                </TableCell>
              )}
              <TableCell>{t('accordance_description')}</TableCell>
              <TableCell>{t('critical_issue')}</TableCell>
              <TableCell>
                <div className="sort">
                  <p>{t('due_date')}</p>
                  <IconSort />
                </div>
              </TableCell>
              {!showCheckboxes && <TableCell>{t('state')}</TableCell>}
              <TableCell>{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row.id}>
                {!showCheckboxes && (
                  <TableCell>
                    <Checkbox
                      checked={row.agreementManagement}
                      onChange={e => handleCheckboxChange(row.id, e.target.checked)}
                      inputProps={{ 'aria-labelledby': `checkbox-${index}` }}
                      disabled={catchIndex[index]?.status !== '2' && catchIndex[index]?.status !== null}
                    />
                  </TableCell>
                )}
                <TableCell
                  id={`checkbox-${index}`}
                  component="th"
                  scope="row"
                  style={{ textAlign: showCheckboxes ? 'inherit' : 'left' }}
                >
                  {row.description}
                </TableCell>
                <TableCell>{row.issue}</TableCell>
                <TableCell className={isDateExpired(row.newDate === null ? row.date : row.newDate) ? 'expired' : ''}>
                  {isDateExpired(row.newDate === null ? row.date : row.newDate)
                    ? `${row.newDate === null ? row.date : row.newDate} (vencido)`
                    : row.newDate === '-'
                    ? row.date
                    : row.newDate}
                </TableCell>
                {!showCheckboxes && (
                  <TableCell>
                    <SelectAgreements
                      options={[
                        { value: AccordanceState.VALID.toString(), optionLabel: 'Vigente' },
                        { value: AccordanceState.COMPLETE.toString(), optionLabel: 'Finalizado' },
                        { value: AccordanceState.CANCEL.toString(), optionLabel: 'Cancelado' },
                      ]}
                      selectValue={row.status.toString()}
                      handleChange={newStatus => handleStatusChangeInPayload(index, newStatus)}
                      disabled={!currentProjectAgreements.assignedToMe}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <button
                    style={{ cursor: 'pointer', maxWidth: '40%', border: 'none', backgroundColor: 'transparent' }}
                    onClick={() => {
                      showDetailModal(row.id);
                      handleCardClick(row.id);
                    }}
                  >
                    <IconArrow />
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {accordanceDetail ? (
        <AccordanceDetail
          isOpen={showDetail}
          closeModal={value => {
            hideModal(value);
            fnEditModal(prev => !prev);
            // setState({ ...state, relatedAccordance: null });
          }}
          accordance={accordanceDetail[0]}
          showJustificationModal={showJustificationModal}
          onApproveHandler={refreshBoard}
          showConfirmDelete={() => setShowConfirm(true)}
          showConfirmDeleteBundle={() => setShowConfirmDeleteBundle(true)}
          changeAccordanceStatus={value => {
            changeStatus(value);
          }}
          role={user.role.id}
          // closeShowRelated={hideModal}
        />
      ) : null}
    </>
  );
};

export default CheckCurrentAgreementsTable;
